/* ****** Custom CSS ****** */


.dx-tab {
    padding: 8px 25px 8px 25px; /* top, right, bottom, left */
}

.dx-tabpanel .dx-tabs {
    display: block;
    border-bottom: none;
    background-color: #e5e5e5;
}


.dx-item dx-tab.dx-item-content.dx-tab-content.dx-tab-text {
    color: #666666; 
}

.dx-tabpanel-tabs {
    padding-bottom:20px;
}

.dx-toolbar {
    padding: 5px 0px 0px 0px;
}

.dx-tabpanel .dx-tabs {
    border-bottom: 1px solid #666666;
}


/* DropDown-List */
.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-list-item-selected {
    background-color: #ac102f;
    color: #ffffff;
}